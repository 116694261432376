var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "keepRecord" },
    [
      _c(
        "van-tabs",
        {
          attrs: { animated: "", sticky: "", type: "card" },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c(
            "van-tab",
            { attrs: { title: "卖料定价", name: "Price" } },
            [
              _c(
                "keep-alive",
                [_c("sellingPrice", { attrs: { activeName: _vm.activeName } })],
                1
              )
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "卖料结算", name: "Settlement" } },
            [_c("keep-alive", [_c("salesSettlement")], 1)],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tabsLeftIcon", on: { click: _vm.back } },
        [_c("van-icon", { attrs: { name: "arrow-left" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }